
import { Component, Vue, Watch } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import { OrderDetail } from '@/types/workOrder.d'
import { FileInfo } from '@/types/common'
import UploadFile from '@/components/uploadFile/Index.vue'
import AMapLoader from '@amap/amap-jsapi-loader'
import { mapKey } from '@/utils/config'
import LocationImg from '@/assets/img/icon_address.png'
import { FetchSuggestionsCallback } from 'element-ui/types/autocomplete'

@Component({
  components: { UploadFile },
  filters: {
    imgListFormat (list: FileInfo[]) {
      const imgTypeList = ['jpeg', 'jpg', 'png']
      const imgList: Array<string> = []
      list.forEach((item: FileInfo) => {
        const str = item.fileName.split('.')
        if (imgTypeList.find((items) => items === str[str.length - 1].toLowerCase())) {
          imgList.push(item.filePrefix + item.fileUrl)
        }
      })
      return imgList || []
    }
  }
})
export default class MyOrderAdd extends Vue {
  private info: OrderDetail = {
    projectId: '',
    orderType: '',
    orderTitle: '',
    orderContent: '',
    orderDate: '',
    address: '',
    fileList: [],
    orderUserId: '',
    longitude: '',
    latitude: '',
    projectPosition: '',
    projectAreaId: ''
  }

  private typeList = []
  private roleList = []

  private dateArr: string[]= []
  private submitShow = false
  private imgList = ['jpeg', 'jpg', 'png']
  private videoList = ['mp4', 'mov']
  private addressShow = false
  private map: any = null
  private marker: Array<any> = []
  private areaList = []
  private addressInfo: { longitude: string; latitude: string; address: string; area: string } = {
    longitude: '',
    latitude: '',
    address: '',
    area: ''
  }

  private rules= {
    projectId: [
      { required: true, message: '请选择所属项目', trigger: ['blur', 'change'] }
    ],
    orderType: [
      { required: true, message: '请选择工单类型', trigger: ['blur', 'change'] }
    ],
    orderTitle: [
      { required: true, message: '请输入工单主题', trigger: ['blur', 'change'] }
    ],
    orderContent: [
      { required: true, message: '请输入工单详情', trigger: ['blur', 'change'] }
    ],
    orderDate: [
      { required: true, message: '请选择工单截止日期', trigger: ['blur', 'change'] }
    ],
    orderUserId: [
      { required: true, message: '请选择工单处理人', trigger: ['blur', 'change'] }
    ],
    address: [
      { required: true, message: '请输入作业地点', trigger: ['blur', 'change'] }
    ]
  }

  private pickerOptions = {
    shortcuts: [
      {
        text: '今天',
        onClick (picker: any) {
          picker.$emit('pick', new Date())
        }
      },
      {
        text: '本周',
        onClick (picker: any) {
          const date = new Date()
          const day = date.getDay() === 0 ? 0 : (7 - date.getDay())
          date.setTime(date.getTime() + 3600 * 1000 * 24 * day)
          picker.$emit('pick', date)
        }
      },
      {
        text: '本月',
        onClick (picker: any) {
          const date = new Date()
          if (date.getMonth() === 11) {
            date.setFullYear(date.getFullYear() + 1, 0, 1)
            date.setTime(date.getTime() - 3600 * 1000 * 24)
          } else {
            date.setMonth(date.getMonth() + 1, 1)
            date.setTime(date.getTime() - 3600 * 1000 * 24)
          }
          picker.$emit('pick', date)
        }
      }
    ]
  }

  get id () {
    return this.$route.params.id as string || ''
  }

  get normalProjectList () {
    return this.$store.state.normalProjectList
  }

  // 如果需要做工作台新增跳转到这个页面且带上项目id，加上这段代码
  // get projectId () {
  //   console.log('projectId', this.$route.query.projectId)
  //   return this.$route.query.projectId as string || ''
  // }

  created () {
    this.id && this.getDetail()
    // if (this.projectId) {
    //   this.info.projectId = this.projectId
    //   this.getTypeList()
    // }
  }

  getTypeList () {
    this.info.orderType = ''
    this.info.orderUserId = ''
    this.info.projectAreaId = ''
    this.getAreaList()
    this.$axios.get(this.$apis.workOrder.selectWorkOrderTypeByList, {
      projectId: this.info.projectId,
      orderTypeStatus: '1'
    }).then((res) => {
      this.typeList = res.list || []
    })
  }

  getRoleList () {
    this.info.orderUserId = ''
    this.$axios.get(this.$apis.workOrder.selectOrderTypeFeedbackUserByList, {
      orderType: this.info.orderType,
      projectId: this.info.projectId
    }).then((res) => {
      this.roleList = res || []
    })
  }

  // 获取区域
  getAreaList () {
    this.$axios.get(this.$apis.project.selectYhProjectAreaByList, {
      projectId: this.info.projectId
    }).then(res => {
      this.areaList = res || []
    })
  }

  getDetail () {
    this.$axios.get(this.$apis.carManage.selectYhVehicleByVehicleId, { vehicleId: this.id }).then(res => {
      if (res) {
        this.info = res
        this.dateArr = [res.insuranceStartTime, res.insuranceEndTime]
      }
    })
  }

  imgRemove (index: number) {
    this.info.fileList.splice(index, 1)
  }

  // 文件上传成功返回
  onSuccess (file: FileInfo) {
    this.info.fileList.push(file)
    this.$nextTick(() => {
      (this.$refs.form as ElForm).clearValidate()
    })
  }

  getAddressInfo () {
    if (this.info.address && this.info.latitude && this.info.longitude) {
      this.addressInfo.address = this.info.address
      this.addressInfo.latitude = this.info.latitude
      this.addressInfo.longitude = this.info.longitude
    }
    this.addressShow = true
    this.$axios.get(this.$apis.project.selectYhProjectMapByProjectId, {
      projectId: this.info.projectId
    }).then((res) => {
      const lng = this.info.longitude ? Number(this.info.longitude) : Number(res.longitude)
      const lat = this.info.latitude ? Number(this.info.latitude) : Number(res.latitude)
      this.$nextTick(() => {
        this.initMap(lng, lat)
      })
    })
  }

  // 初始化地图
  initMap (lng: number, lat: number) {
    if (this.map) {
      this.map.setCenter([lng, lat])
    } else {
      AMapLoader.load({
        key: mapKey,
        version: '2.0',
        plugins: [
          'AMap.MouseTool'
        ]
      }).then((AMap) => {
        this.map = new AMap.Map('map', {
          center: [lng, lat],
          zoom: 15
        })
        this.map.on('click', this.mapClick)
      })
    }
  }

  mapClick (e: any) {
    let geocoder = null
    this.map.plugin(['AMap.Geocoder'], () => {
      geocoder = new AMap.Geocoder({
        city: '全国'
      })
      const lnglat = [e.lnglat.lng, e.lnglat.lat]
      geocoder.getAddress(lnglat, (status: any, result: any) => {
        if (status === 'complete' && result.info === 'OK') {
          const site = result.regeocode.formattedAddress || ''
          this.addMarker(e.lnglat.lng, e.lnglat.lat)
          this.addressInfo.address = site
          this.addressInfo.latitude = e.lnglat.lat.toString()
          this.addressInfo.longitude = e.lnglat.lng.toString()
        }
      })
    })
  }

  addMarker (lng: number, lat: number) {
    this.map.remove(this.marker)
    if (lng && lat) {
      this.marker = []
      const marker: any = new AMap.Marker({
        map: this.map,
        position: new AMap.LngLat(lng, lat),
        offset: new AMap.Pixel(-20, -10),
        icon: LocationImg
      })
      this.marker.push(marker)
      this.map.add(marker)
      this.map.setCenter([lng, lat])
    }
  }

  // 详细地址输入
  addrFetch (queryString: string, cb: FetchSuggestionsCallback) {
    AMapLoader.load({
      key: mapKey,
      version: '2.0',
      plugins: [
        'AMap.AutoComplete'
      ]
    }).then((AMap) => {
      // 实例化Autocomplete
      const autoComplete = new AMap.Autocomplete({
        city: this.addressInfo.area
      })
      autoComplete.search(this.addressInfo.area + queryString, (status: string, result: AMap.Autocomplete.SearchResult) => {
        if (status === 'complete') {
          cb(result.tips)
        }
      })
    })
  }

  // 点击详细地址下拉
  addrChange (data: AMap.Autocomplete.Tip) {
    if (data.location) {
      this.addressInfo.address = data.name
      this.addressInfo.latitude = data.location.getLat().toString()
      this.addressInfo.longitude = data.location.getLng().toString()
      this.map.setCenter([Number(this.addressInfo.longitude), Number(this.addressInfo.latitude)])
    }
  }

  submitAddress () {
    this.info.address = this.addressInfo.address
    this.info.latitude = this.addressInfo.latitude
    this.info.longitude = this.addressInfo.longitude
    this.handleClose()
  }

  handleClose () {
    this.addressShow = false
    this.addressInfo = {
      address: '',
      latitude: '',
      longitude: '',
      area: ''
    }
  }

  saveForm () {
    (this.$refs.form as ElForm).validate(valid => {
      if (valid) {
        this.$axios.post(this.$apis.workOrder.insertWorkOrder, {
          ...this.info
        }).then(() => {
          this.$message.success('保存成功')
          this.$router.back()
          // this.$router.push({
          //   name: 'myOrder'
          // })
        })
      }
    })
  }
}
